<template>
    <div>
        <h2 class="money-title">Ведите код из SMS</h2>
        <p class="big-text mt-1">
            Мы отправили его на номер: +7 (999) 843 70-79
        </p>
        <v-form ref="form">
            <Field class="mt-10" anketaName="codeSms" />

    <!--        <p class="error-text mt-2" v-show="$forms.settings.error">-->
    <!--            {{$forms.settings.error}}-->
    <!--        </p>-->

            <timer
                v-if="!timerEnded"
                :key="repeatSopd"
                label="Выслать повторно через"
                :seconds="seconds"
                append="сек."
                class="sopd-timer"
                @complete="timerEnded = true"
            />
            <a
                v-else
                class="sopd-link ml-4 pt-2 d-inline-block"
                @click.prevent="resendSms"
                >Отправить смс повторно</a
            >

            <div class="fixed-btn container justify-space-between d-flex mr-0">
                <v-btn
                    :loading="loading"
                    @click="back"
                    class="main-btn btn-back ma-0"
                >
                </v-btn>

                <v-btn
                    :color="$root.incomingData.theme"
                    :loading="loading"
                    @click="confirmCode"
                    class="main-btn btn-next ma-0"
                >
                    Далее
                </v-btn>
            </div>

        </v-form>
    </div>
</template>

<script>
import Field from "@/components/Field";
import Timer from "@/components/controls/Timer";

export default {
    components: { Timer, Field },

    props: {
        settings: Object
    },

    data: () => ({
        timerEnded: false,
        loading: false,
        seconds: 120,
        repeatSopd: 0,
        error: '',
    }),

    methods: {
        resendSms() {
            this.timerEnded = false;
            this.repeatSopd++;
            this.$forms.settings.action(true);
        },

        confirmCode() {
            if(this.$refs.form.validate()) {
                this.$forms.settings.action();
            }
        },

        back() {
            // if(this.$forms.settings.modal) {
            //     console.log(this.$forms.settings.modal)
            //    this.$root.steps.setStep('SinglePurchase')
            //    // this.$modals.open(this.$forms.settings.modal)
            // }

            if (this.$forms.settings.page) {
                this.$root.steps.setStep(this.$forms.settings.page);
            }
        }
    }
};
</script>

<style scoped></style>
