<template>
    <div>
        <div
            @click="$root.steps.setStep('ChoiceCard', '', 'PaymentMethods')"
            class="block-link pointer d-flex align-center mt-10"
        >
            <div class="block-logo info-block-img mr-4">
                <img class="scaled-img" v-if="$root.incomingData.theme === 'purple'"  src="@/assets/img/card-purple.svg" alt="" />
                <img v-else src="@/assets/img/card.svg" alt="" />
            </div>

            <h4 class="main-title">Добавить/изменить карту</h4>

            <img
                class="arrow-right"
                src="@/assets/img/arrow-right.svg"
                alt=""
            />
        </div>
<!--        <div-->
<!--            class="block-link pointer d-flex align-center mt-6"-->
<!--        >-->
<!--            <div class="block-logo info-block-img mr-4">-->
<!--                <img src="@/assets/img/bank.svg" alt="">-->
<!--            </div>-->

<!--            <h4 class="main-title">По реквизитам</h4>-->

<!--            <img-->
<!--                class="arrow-right"-->
<!--                src="@/assets/img/arrow-right.svg"-->
<!--                alt=""-->
<!--            />-->
<!--        </div>-->
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
