<template>
  <div>
    <h4 class="big-title">
      {{settings.title}}
    </h4>

    <p class="big-text fz14 mt-1">
      {{settings.text}}
    </p>

    <v-btn
        :color="$root.incomingData.theme"
        @click="$root.modals.close()"
        class="main-btn mt-8"
    >
      Хочу подключить
    </v-btn>

    <p @click="$root.steps.setStep(5); $root.modals.close()" class="pointer text-center main-title mt-8">Продолжить без подключения</p>
  </div>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$root.modals.settings;
    }
  },
}
</script>

