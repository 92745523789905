import {
    yearsBeforeNow,
    makeMoment,
} from "@/helpers/helpers";

let md5 = require('js-md5');

function checkDigitsCount(value, length) {
	let val = "";
	if (value) val = value;

	return val.replace(/\D/g, '').length == length;
}



// // добавляем в поля с fieldType=file
// let fileFieldLoadingAttributes = {
// 	value: {},
// 	isUploaded: false,
// 	uploading: false,
// 	success: false,
// 	errorMessage: '',
// 	alertMessage: '',
// 	send(method, params) {
// 		this.errorMessage = '';
// 		this.alertMessage = '';
//
// 		method(params)
// 			.then(() => this.uploaded())
// 			.catch(() => this.uploadingError());
// 	},
// 	uploaded() {
// 		this.uploading = false;
// 		this.success = true;
//
// 		this.isUploaded = true;
// 	},
// 	uploadingError() {
// 		this.errorMessage = "Ошибка отправки";
// 		this.uploading = false;
// 		this.success = false;
//
// 		this.isUploaded = false;
// 	},
// 	validate() {
// 		if (!this.isUploaded) {
// 			this.value = {};
// 			this.alertMessage = 'Файл не загружен';
// 		}
// 		return this.isUploaded;
// 	}
// };
// // Правила получения очищенных значений полей
let cleanRules = {
	phone(str) {
		return str ? str.replace('+', '').replace(/\s+/g, '') : '';
	},
	withoutSpace(str) {
		return str.replace(/\s+/g, '');
	}
}
let validationRules = {
	required: value => !!value || 'Заполните',
	email:
		(
			value,
			pattern = /^(([^а-яА-ЯёЁ<>()[\]\\.,;:\s@"]+(\.[^а-яА-ЯёЁ<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		) =>
			pattern.test(value) || 'Введите корректный e-mail',
	phoneFirstDigitIs9:
		(
			value,
			pattern = /^(\+7 9)./
		) =>
			pattern.test(value) || 'Номер должен начинаться с 9',
	phone: value => checkDigitsCount(value, 11) || 'Номер введен не полностью',
	code: value => checkDigitsCount(value, 5) || 'СМС код введен не полностью',
	date: value => checkDigitsCount(value, 8) || 'Дата введена не полностью',
    correctDate: value =>
        makeMoment(value).isValid() || 'Дата введена некорректно',
    birthdate: {
        min: yearsBeforeNow(70),
        max: yearsBeforeNow(18),
        limitations: value => {
            let date = makeMoment(value);

            return ( date.isValid()
                    && date > validationRules.birthdate.min
                    && date < validationRules.birthdate.max )
                || 'Допустим возраст от 18 до 70 лет'
        },
    },
	sms: value => checkDigitsCount(value, 4) || 'Введите код из sms',
};

const VR = validationRules;


export default {
	data() {
		return {
			anketa: {
				isOpenModal: false,
				//форма Паспорт (Passport)
				/* Как определять атрибуты поля?
				*
				* fieldType - устанавливает тип поля, отрисовка в компоненте Field
				* rules и другие атрибуты соответствуют атрибутам компонент Vuetify (https://vuetifyjs.com)
				* mask - https://www.npmjs.com/package/v-mask
				* isMoney, isCyrilic, isCyrilicIssued, isCyrilicPlace, isEngEmail - типы ввода обрабатывающиеся компонентой FiledText
				* type: "tel" - используется для отображения цифровой клавиатуры в мобильном
				* input() - унифицированный метод реакции на ввод в поле*/

                phone: {
                    value: '',
                    mask: '+7 ### ### ## ##',
                    label: 'Номер телефона',
                    // isPhone: true,
                    cleanValue() {
                        return cleanRules.phone(this.value);
                    },
                    focus: (val) => {
                        if(val === '') {
                            this.$anketa.phone.value = '+7 ';
                        }
                    },
                    grid: {
                        cols: 12,
                        sm: 8
                    },
                    rules: [
                        VR.required,
                        VR.phoneFirstDigitIs9,
                        VR.phone,
                    ],
                    type: "tel",
                },

                password: {
                    value: '',
                    hashValue: '',
                    placeholder: 'Пароль',
                    label: 'Пароль',
                    grid: {
                        cols: 12,
                        sm: 8
                    },
                    input: (value) => {
                        this.$anketa.password.hashValue = md5(value);
                    },
                    rules: [
                        VR.required,
                    ],
                    type: "password",
                },
                birthdate: {
                    value: '',
                    label: 'Дата рождения',
                    fieldType: 'date',
                    placeholder: 'Дата рождения',
                    // datapicker: {},
                    max: VR.birthdate.max.format('YYYY-MM-DD'),
                    min: VR.birthdate.min.format('YYYY-MM-DD'),
                    grid: {
                        cols: 12,
                        sm: 6,
                    },
                    input: () => {},
                    rules: [
                        VR.required,
                        VR.date,
                        VR.correctDate,
                        VR.birthdate.limitations,
                    ],
                },
                paymentDate: {
					fieldType: "slider",
					type: "tel",
					value: 10,
					min: 0,
					max: 20,
					step: 1,
					// append: "от 2 до 5",
					grid: {
						cols: 12,
						sm: 10
					},
					change: (value) => {
                        this.$connector.getToken().then((data) => {
                            if (data.apisetup.status === "done") {
                                localStorage.setItem("token", data.apisetup.token);
                                localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                                this.$connector.changePaymentDates(value, 0,true)
                                    .then(data => {
                                        if(data.apichangepaymentdates.isSuccess) {
                                            this.incomingData.dateChangeCost = parseInt(data.apichangepaymentdates.amountPay)
                                        }
                                    })
                            }
                        })
					},
				},

                codeSms: {
                    mask: "#####",
                    placeholder: "Код из SMS",
                    type: "tel",
                    rules: [VR.required, VR.code],
                    success: false,
                    hideValue: true,
                    errorMessage: "",
                    value:''
                },
			},
		}
	}
}
