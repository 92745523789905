<template>
    <p class="time second-text ml-4 pt-2" v-if="timerValue > 0">
        {{ label }} {{ timerValue }} {{ append }}
    </p>

    <p class="blue-text second-text " v-else @click="start">
        {{ endedText }}
    </p>
</template>

<script>
export default {
    props: {
        label: String,
        seconds: Number,
        append: String,
        endedText: String
    },
    mounted() {
        this.start();
    },
    data: () => ({
        interval: null,
        timerValue: 0,
    }),
    methods: {
        start() {
            this.timerValue = this.seconds || 0;
            this.interval = setInterval(this.decrease, 1000);
        },
        decrease() {
            if (this.timerValue > 1) {
                this.timerValue--
            } else {
                this.$emit('complete');
                clearInterval(this.interval);
            }
        }
    }
}
</script>

<style lang="scss">
    p.blue-text {
        cursor: pointer;
    }
</style>
