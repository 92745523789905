<template>
    <div class="slider">
        <h4 class="slider__label">{{ label }}</h4>
        <slot></slot>
        <v-row class="text-center">
            <v-col>
                <v-slider
                    v-model="sliderValue"
                    :min="min"
                    :max="max"
                    :step="step"
                    ticks
                    :color="color"
                    track-color="#E7E7E7"
                    :tick-size="tickSize"
                    :tick-labels="tickLabels"
                    @change="change"
                    thumb-label="always"
                >
                    <template v-slot:thumb-label>
                      <slot name="thumb-label"></slot>
                    </template>
                </v-slider>

                <slot name="labelSliderSumm"></slot>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// import moneyFormat from '@/helpers.js';

export default {
    name: "Slider",
    props: {
        nameSlider: Object,
        value: Number,
        min: Number,
        label: String,
        max: Number,
        step: Number,
        color: String,
        comment: String,
        tickSize: Number,
        tickLabels: Array,
        sliderArray: Array,
        sliderObj: Array,
    },
    computed: {
        sliderValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },

        sliderSum() {
            return this.sliderValue;
        },
    },

    methods: {
        change(val) {
            this.$emit("change", val);
        }
    }
};
</script>

<style lang="scss">
.slider {
    margin-top: 16px;
}

.v-slider__tick {
    background-color: inherit !important;
}

.v-slider {
    .v-slider__track-container {
        height: 8px !important;

        > div {
            border-radius: 30px;
        }
    }

    .v-slider__thumb {
        width: 20px;
        height: 20px;
        background-color: white !important;
        box-shadow: 0px 1px 4px rgba(10, 10, 13, 0.15);
        border-radius: 12px;

        &:before {
            width: 0px;
            height: 0px;
        }
    }

    .v-slider__thumb-label-container {
        .v-slider__thumb-label {
            background-color: transparent !important;
            display: flex;
            width: 40px !important;

            p {
                font-weight: 600;
                font-size: 12px;
                line-height: 1.3em;
                text-align: center;
                color: #0a0a0d !important;
            }
        }
    }
}

.v-slider--horizontal {
    margin: 0;
}

.v-slider__tick {
    .v-slider__tick-label {
        font-size: 14px !important;
        padding-top: 5px;
        transform: translateX(0px) !important;
    }

    &:last-child {
        .v-slider__tick-label {
            transform: translateX(-33.333%) !important;
        }
    }
}

.v-messages {
    display: none;
}

.slider__label {
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3em;
    padding-left: 16px;
    margin-bottom: 8px;

    display: flex;
    align-items: center;

    color: #0a0a0d;
}
</style>
