<template>
    <div>
        <div
            v-for="good in items" :key="good.Name"
            class="start__header d-flex align-center justify-space-between mb-2"
        >
            <div class="d-flex align-center">
                <div class="header-icon">
                    <img
                        v-if="$root.incomingData.theme === 'purple'"
                        src="@/assets/img/renessans/gift-icon.svg"
                        alt=""
                        width="40"
                    >
                    <img
                        v-else
                        src="@/assets/img/gift-icon.png"
                        alt=""
                    >
                </div>
                <div class="header-text second-text">
                    {{ good.name }}
                </div>
            </div>

            <p class="header-price main-text semibold">
            <span
                v-show="good.Count > 1"
                class="header-text mr-0"
            >
                {{ good.count }} ×
            </span>
                {{ +good.price | money }} ₽
            </p>
        </div>
    </div>
</template>

<script>
import {moneyFormat as money} from "@/helpers/helpers";

export default {
    filters: {money},

    props: {
        items: Array
    }
}
</script>

<style lang="scss" scoped>
.header-icon {
    padding: 12px;
    background: #E7E7E7;
    border-radius: 8px;
    display: inline-block;
}

.header-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: 'SFPro', sans-serif;
    color: #848485;
    margin-left: 16px;
    margin-right: 12px;
}

.header-price {
    white-space: nowrap;
}
</style>
