<template>
    <div class="main-page">
        <p class="second-title">Кредитная карта</p>

        <div class="credit-card-box-with-number-card">
            <div class="text-block">
                <p class="main-text m-5">Доступно по карте</p>
                <p class="money-title">
                    {{ incomingData.balance }}<span> &#8381;</span>
                </p>
                <div
                    class="container-info-limit_slider blue-slider btn-disabled"
                    id="slider-limit"
                ></div>
                <!--        incomingData.monthlyPay != '0,00'-->
                <p class="text-14 m-10">
                    Платеж: до {{ incomingData.nextPayDate }} -
                    {{ incomingData.monthlyPay | money }}
                    <!--          {% if monthlyPay != '0,00' %}Платеж: до {{ next_pay_date|date:"d E" }} &mdash; {{ monthlyPay}}  &#8381;{% endif %}-->
                </p>
            </div>
            <div class="card-block">
<!--                <p class="second-text white&#45;&#45;text">**{{ cardNumber }}</p>-->
                <img src="@/assets/img/visa-logo.svg" />
            </div>
        </div>


        <text-block closes class="mt-6 pa-4 pl-6 pr-10 purple">
            <p class="main-title white--text">Кредит под 12% годовых</p>
            <p class="block-text white--text">На любые цели</p>
        </text-block>

        <div class="main-page-info">
            <h4 class="second-title mt-8">Займ</h4>
            <div class="info-block pointer">
                <div class="info-block-header">
                    <div class="info-block-img block-logo">
                        <img src="@/assets/img/debt.svg" alt="" />
                    </div>

                    <div class="ml-5 mt-4">
                        <p class="main-text">Задолженность</p>

                        <h4 class="money-title">
                            {{ incomingData.debt | money }}
                        </h4>
                        <p class="main-text">
                            Платеж {{ incomingData.nextPayDate }}
                            {{ incomingData.nextPay | money }}
                        </p>
                    </div>
                </div>
            </div>

            <h4 class="second-title mt-8">Оплата частями</h4>
            <div @click="redirectToPurchase" class="info-block pointer">
                <div class="info-block-header">
                    <div class="info-block-img block-logo">
                        <img src="@/assets/img/percent.svg" alt="" />
                    </div>

                    <div class="ml-5 mt-4">
                        <p class="main-text">Общая задолженность</p>

                        <h4 class="money-title">
                            {{ incomingData.nextPay | money }}
                        </h4>
                        <p class="main-text">
                            Платеж {{ incomingData.nextPayDate }}
                            {{ incomingData.nextPay | money }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import money from "@/helpers/moneyFormat.js";
import TextBlock from "@/components/controls/TextBlock";

export default {
    components: { TextBlock },
    computed: {
        anketa() {
            return this.$root.anketa;
        },

        incomingData() {
            return this.$root.incomingData;
        },

        // cardNumber() {
        //     // return this.$root.incomingData.card_number
        //     //     .toString()
        //     //     .replace(/\b[0-9]{0,12}/g, "");
        // },
    },

    // created() {
    //     this.$connector.accountInfo()
    //     .then(data => {
    //         console.log(data)
    //     })
    // },

    filters: {
        money,
    },

    methods: {
        redirectToPurchase(){
            if(this.$root.incomingData.applications.length > 1){
                this.$root.steps.setStep('SeveralPurchases');
            } else {
                this.$root.steps.setStep('SinglePurchase');
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.header-icon {
    padding: 12px;
    background: #e7e7e7;
    border-radius: 8px;
    display: inline-block;
}

.header-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "SFPro", sans-serif;
    color: #848485;
    margin-left: 16px;
    margin-right: 12px;
}

.header-price {
    white-space: nowrap;
}
</style>
