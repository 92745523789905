<template>
    <div class="alert">
        <v-alert :value="activeAlert" transition="scale-transition" :type="settings.type">
            {{settings.text}}
        </v-alert>
    </div>
</template>

<script>
export default {
    props: {
        settings: Object
    },

    watch: {
        settings() {
            if(this.settings.text) {
                this.activeAlert = true;
                setTimeout(() => {
                    this.activeAlert = false;
                }, 3000)
            }
        }
    },

    data: () => ({
        activeAlert: false
    })
}
</script>

<style lang="scss" scoped>
    .alert {
        position: absolute;
        right: 20px;
        top: 40px;
        max-width: 550px;
        min-width: 240px;
        z-index: 1000;
    }

    @media (max-width: 440px) {
        .alert {
            width: 100%;
            max-width: 100%;
            right: 0;
            //top: 40px;

            .v-alert {
                border-radius: 0px 0px 6px 6px !important;
            }
        }
    }
</style>
