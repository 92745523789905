export function moneyFormat(value) {
	return value.toLocaleString();
}

export function card(value) {
    let cardType = 'Mir'
    if(value.split('')[0] === '4') {
        cardType = 'Visa';
    } else if(value.split('')[0] === '5') {
        cardType = 'MasterCard';
    }
    return cardType + value.replace(/(.{8})/, "");
}
