<template>
    <div class="input-radio">
        <input
            :class="$root.incomingData.theme"
            :checked="checked"
            :id="`selection-${value}`"
            @change="$emit('change', id || value)"
            :name="name"
            type="radio"
        />
        <label :for="`selection-${value}`">
            <slot></slot>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        label: [String, Number],
        name: [String, Number],
        value: [String, Number],
        id: [String, Number],
        checked: Boolean,
    },
};
</script>

<style scoped></style>
