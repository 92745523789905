<template>
    <div :class="{'input-error' : errorMessage}">
        <div class="input-wrapper input-code">
            <v-text-field
                v-mask="mask"
                v-model="fieldValue"
                :key="updateKey"
                :autofocus="autofocus"
                :placeholder="placeholder || virtualPlaceholder"
                :type="type"
                solo
                flat
                :outlined="outlined"

                :hide-details="$root.isMobile ? 'auto' : false"
                height="47px"
                background-color="white"
                validate-on-blur
                :rules="rules"
                :error.sync="hasError"
                :disabled="isDisabled"
            >
            </v-text-field>
        </div>

        <Timer
            label="Запросить повторно через"
            endedText="Запросить повторно"
            :seconds="20"
            append="секунд"
            class="mt-3"
            v-show="!errorMessage"
            @complete="getSms"
        />

        <p class="error-text mt-2" v-show="errorMessage">
            {{errorMessage}}
        </p>
    </div>
</template>

<script>

import Timer from "@/components/controls/Timer";
export default {
    components: {Timer},
    props: {
        value: [Number, String, Boolean],
        label: String,
        comment: String,
        append: String,
        mask: String,
        type: String,
        rules: Array,
        placeholder: String,
        outlined: Boolean,
        getSms: Function,
        errorMessage: String,
        disabled: Boolean,
    },
    computed: {
        fieldValue: {
            get() {
                if (this.isMoney && this.value) {
                    return this.value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
                } else {
                    return this.value;
                }
            },
            set(val) {
                let oldval = val;

                if (val != this.value) {
                    this.$emit('input', val);

                    if (val != oldval) {
                        // перерисовка позволяет видеть в поле ввод с ограничениями
                        this.updateKey++;
                        // autofocus позволяет мобильной клавиатуре оставаться на месте
                        // при перерисовке поля по updateKey
                        this.autofocus = true;
                    } else {
                        this.autofocus = false;
                    }
                }
            }
        },
        appendText() {
            return this.append;
        },
        virtualPlaceholder() {
            if (this.mask) {
                return this.mask.replace(/#/g, '0');
            } else {
                return null;
            }
        },
        isDisabled() {
            return this.disabled;
        }
    },
    data() {
        return {
            updateKey: 0,
            autofocus: false,
            hasError: false,
        }
    },
}
</script>

<style lang="scss">
.input-code {
    .v-text-field__details {
        display: none !important;
    }
}
</style>