<template>
  <div>
    <v-expand-transition>
      <component
        :is="fieldTypes[field.fieldType || 'text']"
        v-show="!(field.visible === false)"
        v-model="field.value"
        v-bind="field"
        @input="field.input"
        @focus="field.focus"
        :class="anketaName"
        :outlined="outlined"
      ></component>
    </v-expand-transition>
  </div>
</template>

<script>
import text from "./fields/Text"
import codeSMS from "@/components/fields/codeSMS";
import date from "@/components/fields/Date";

export default {
  props: {
    anketaName: String,
    outlined: Boolean,
  },
  computed: {
    field() {
      return this.$root.anketa[this.anketaName];
    },
  },
  data: () => ({
    fieldTypes: {
        text,
        date,
        codeSMS
    },
  }),
}
</script>
