<template>
    <div>
        <div class="block-logo info-block-img d-flex mx-auto">
<!--            <img src="@/assets/img/pdf-icon.svg" alt="" />-->
            <img class="scaled-img" v-if="$root.incomingData.theme === 'purple'"  src="@/assets/img/docs-purple.svg" alt="" />
            <img v-else src="@/assets/img/docs.svg" alt="" />
        </div>

        <p class="text-center text-link mt-3">
            {{settings.title}}
        </p>

        <div class="mt-6">
            <a @click="openPdf" target="_blank" :href="settings.link">
                <v-btn
                    :color="$root.incomingData.theme"
                    class="main-btn mt-3"
                >
                    Скачать
                </v-btn>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        settings() {
            return this.$root.modals.settings;
        },
    },

    methods: {
        openPdf() {
            if(this.settings.pdfLink) {
                let pdfWindow = window.open("")
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='"+this.settings.pdfLink+"'></iframe>"
                )

            }
        }
    }
};
</script>
