<template>
    <v-dialog
        @click:outside="!persistent ? $root.modals.close() : true"
        v-model="hasActive"
        :persistent="persistent"
        class="modal"
    >
        <component
            :is="modals[value]"
            v-bind="modal"
            class="modal__body"
            @close="$root.modals.close()"
        />
    </v-dialog>
</template>

<script>
import mainModal from "@/components/modals/mainModal";
import tooltipModal from "@/components/modals/tooltipModal";
import changeSheduleModal from "@/components/modals/changeSheduleModal";
import docsModal from "@/components/modals/docsModal";

let modals = {
    mainModal,
    tooltipModal,
    changeSheduleModal,
    docsModal,
};

export default {
    props: {
        value: {
            type: String,
            default: "нет",
        },
    },
    computed: {
        modal() {
            return this.$root.modals || {};
        },

        persistent() {
            return this.modal.settings?.persistent;
        },

        hasActive() {
            return this.$root.modals.hasActive();
        },
    },
    data: () => ({
        // SolutionExpectation
        modals,
    }),
};
</script>
