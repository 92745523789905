<template>
    <div>
        <h4 class="block-title font-weight-bold text-center">
            {{ settings.title }}
        </h4>

        <div
            :key="data.title"
            v-for="data in settings.body"
            class="d-flex justify-space-between mt-6 text-center"
        >
            <p class="fz16 grey--text">{{ data.text }}</p>
            <p class="fz16">{{ data.value }}</p>
        </div>

        <v-btn
            @click="$root.modals.close()"
            :color="$root.incomingData.theme"
            class="main-btn mt-8"
        >
            {{ settings.button }}
        </v-btn>
    </div>
</template>

<script>
export default {
    computed: {
        settings() {
            return this.$root.modals.settings;
        },
    },
    mounted() {
        console.log("tooltipModal this.$root", this.$root);
    }
};
</script>
