<template>
    <div>
        <div class="mt-6 block-link">
            <p class="main-text">Дата текущего платежа</p>
            <h2 class="money-title">
                {{ incomingData.currentDate | dateWithYear }}
            </h2>
        </div>

        <div class="mt-12 block-link">
            <p class="main-text">Новая дата платежа</p>
            <h2 class="money-title">{{ newDatePayment }}</h2>
        </div>
        <div v-if="incomingData.dateChangeCost > 0" class="mt-12 info-block">
            <p class="main-text">Стоимость изменения даты</p>
            <h2 class="money-title">
                {{ incomingData.dateChangeCost | money }}
            </h2>
        </div>

        <Slider
            v-model="$anketa.paymentDate.value"
            v-bind="$anketa.paymentDate"
            @change="changeSlider"
            icon="&#8381;"
            class="mt-12"
            :color="$root.incomingData.theme"
        >
            <div slot="thumb-label">
                <p>{{ $anketa.paymentDate.value }} дней</p>
            </div>

            <div class="d-flex justify-space-between" slot="labelSliderSumm">
                <p class="second-text">{{ $anketa.paymentDate.min }} дней</p>
                <p class="second-text">{{ $anketa.paymentDate.max }} дней</p>
            </div>
        </Slider>
        <v-btn
            @click="approveChange"
            :disabled="this.errorMessage"
            :loading="loading"
            class="main-btn fixed-btn"
            :color="$root.incomingData.theme"
        >
            {{
                sliderChanged && incomingData.dateChangeCost === 0
                    ? "Подтвердить изменение"
                    : `Оплатить ${incomingData.dateChangeCost} руб.`

            }}
        </v-btn>
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import money from "@/helpers/moneyFormat.js";
import moment from "moment";
import {dateWithYear} from "@/helpers/Date";

export default {
    components: {Slider},

    computed: {
        incomingData() {
            return this.$root.incomingData;
        },

        newDatePayment() {
            return moment(this.incomingData.currentDate)
                .add(this.$anketa.paymentDate.value, "days")
                .format("ll");
        },
    },

    filters: {
        money,
        dateWithYear,
    },

    mounted() {
        this.$anketa.paymentDate.change(10);
    },

    methods: {
        changeSlider(value) {
            this.loading = true;
            this.errorMessage = false;

            this.$connector.getToken().then((data) => {
                if (data.apisetup.status === "done") {
                    localStorage.setItem("token", data.apisetup.token);
                    localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                    this.$connector.changePaymentDates(value, 0, true)
                        .then(answer => {
                            this.sliderChanged = false;
                            let data = answer.apichangepaymentdates;

                            if (data.isSuccess) {
                                this.incomingData.dateChangeCost = parseInt(data.amountPay);

                                if (parseInt(data.amountPay) === 0) {
                                    this.sliderChanged = true;
                                }
                            } else if (data.result == 1 || data.result == 5) {
                                this.$root.alertSettings = {
                                    text: 'Превышено число возможных переносов',
                                    type: "error",
                                };

                                this.errorMessage = true;
                            } else {
                                this.$root.alertSettings = {
                                    text: 'Произошла ошибка. Обратитесь в техническую поддержку сервиса',
                                    type: "error",
                                };
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                }
            })
        },

        approveChange() {
            this.loading = true;
            this.$connector.getToken().then((data) => {
                if (data.apisetup.status === "done") {
                    localStorage.setItem("token", data.apisetup.token);
                    localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                    this.$connector
                        .changePaymentDates(this.$anketa.paymentDate.value, 0, false)
                        .then(answer => {
                            let data = answer.apichangepaymentdates;

                            if (this.sliderChanged) {
                                if (data.isSuccess && data.result == 0) {
                                    this.$root.steps.setStep("SinglePurchase");
                                    this.$root.alertSettings = {
                                        text: "Дата платежа успешно изменена!",
                                        type: "success",
                                    };
                                } else if (data.isSuccess && data.result == 9) {
                                    this.$root.steps.setStep("SmsPage", {
                                        action: this.checkSms,
                                        page: 'ChangePaymentDate'
                                    });
                                } else {
                                    this.$root.alertSettings = {
                                        text: 'Произошла ошибка. Обратитесь в техническую поддержку сервиса',
                                        type: "error",
                                    };
                                }
                            } else {
                                if (data.isSuccess) {
                                    this.$root.alertSettings = {
                                        text: "Дата платежа успешно изменена, денежные средства списаны с привязанной для оплаты карты.",
                                        type: "success",
                                    };

                                    this.$root.steps.setStep('SinglePurchase');
                                } else {
                                    this.$root.alertSettings = {
                                        text: 'Произошла ошибка. Обратитесь в техническую поддержку сервиса',
                                        type: "error",
                                    };
                                }
                            }
                        })
                        .finally(() => (this.loading = false))
                }
            }).finally(() => (this.loading = false));
        },

        checkSms(isResend) {
            if (!isResend) {
                this.$connector.getToken().then((data) => {
                    if (data.apisetup.status === "done") {
                        localStorage.setItem("token", data.apisetup.token);
                        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                        this.$connector
                            .changePaymentDates(
                                this.$anketa.paymentDate.value,
                                this.$anketa.codeSms.value,
                                false
                            )
                            .then((data) => {
                                if (data.apichangepaymentdates.result == 0) {
                                    this.$root.alertSettings = {
                                        text: "Дата платежа успешно изменена!",
                                        type: "success",
                                    };
                                    this.$root.steps.setStep("SinglePurchase");
                                } else if (data.apichangepaymentdates.result == 8) {
                                    this.$connector
                                        .changePaymentDates(
                                            this.$anketa.paymentDate.value,
                                            0,
                                            false
                                        )
                                        .then(() => {
                                            this.$root.alertSettings = {
                                                text: "Срок жизни кода истек",
                                                type: "error",
                                            };
                                        });
                                } else {
                                    this.$root.alertSettings = {
                                        text: "Некорректный СМС код",
                                        type: "error",
                                    };
                                }
                            });
                    }
                })
            } else {
                this.$connector.getToken().then((data) => {
                    if (data.apisetup.status === "done") {
                        localStorage.setItem("token", data.apisetup.token);
                        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                        this.$connector
                            .changePaymentDates(
                                this.$anketa.paymentDate.value,
                                0,
                                false
                            )
                            .then((data) => {
                                if (data.apichangepaymentdates.result == 0) {
                                    this.$root.steps.setStep('SinglePurchase');
                                    this.$root.alertSettings = {
                                        text: 'Дата платежа успешно изменена!',
                                        type: 'success'
                                    }
                                } else if (data.apichangepaymentdates.result == 9) {
                                    this.$root.steps.setStep("SmsPage", {
                                        action: this.checkSms,
                                        page: 'ChangePaymentDate'
                                    });
                                } else {
                                    this.errorMessage = data.apichangepaymentdates.isError;
                                }
                            });
                    }
                })

            }
        },
    },

    data: () => ({
        loading: false,
        sliderChanged: false,
        errorMessage: "",
    }),
};
</script>

<style scoped></style>
