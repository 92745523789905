<template>
    <div class="phone-block">
        <v-btn
            @click="active = !active"
            class="phone-btn"
        >
            <img v-if="$root.incomingData.theme === 'purple'" src="../../assets/img/phone-purple.png" alt="">
            <img v-else src="../../assets/img/phone.png" alt="">
        </v-btn>


        <div :class="{active}" class="phone-value ">
            <a href="tel:+78002227269">8 (800) 222-72-69</a>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        active: false
    }),
}
</script>

<style lang="scss" scoped>
.phone-block {
    position: fixed;
    right: 24px;
    bottom: 85px;

    .phone-btn {
        width: 46px !important;
        height: 46px !important;
        min-width: 0 !important;
        border-radius: 50%;
        background-color: #F2F2F3 !important;
        box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.15) !important;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 3;
    }

    .phone-value {
        font-family: SFProSemiBold;
        padding-top: 3px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        background: #FFFFFF;
        transition: width 0.3s;
        overflow: hidden;
        width: 0;
        right: 10px;
        height: 45px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-weight: 600;
        font-size: 15px;
        line-height: 1.3em;
        letter-spacing: -0.25px;
        border: 0px solid #E7E7E7;
        color: #007AFF;
        border-radius: 24px;
        padding-left: 16px;

        &.active {
            width: 205px;
            border-width: 0.5px;
            height: 46px;
            box-shadow: 0px 8px 24px -4px rgba(10, 10, 13, 0.1);
        }
    }
}
</style>
