<template>
    <div>
        <v-expansion-panels class="flex-column" accordion>
            <div
                v-for="(data, index) in $root.incomingData.applications"
                :key="index"
            >
                <div v-if="data.earlyRedemption === 0">
                    <h4 class="second-title mt-4">
                        {{ $root.incomingData.theme === 'purple' ? 'График оплат покупки от' : 'Покупка'}}
                        {{ data.dtClientAgreed | changeDateFormat }}
                    </h4>
                    <v-expansion-panel class="info-block">
                        <div class="d-flex justify-space-between align-start">
                                <div @click="$root.steps.setStep('SinglePurchase', getRepaidLoan(data), 'RepaidLoans')" class="info-block-header pointer">
                                <div class="info-block-img block-logo">
                                    <!--                                    <img src="@/assets/img/percent.svg" alt="" />-->
                                    <img v-if="$root.incomingData.theme === 'purple'" src="@/assets/img/percent-purple.svg" alt="" />
                                    <img v-else src="@/assets/img/percent.svg" alt="" />
                                </div>
                                <div class="ml-5 mt-4">
                                    <p class="main-text">Заказ {{data.orderIdPartner}}</p>

                                    <h4 class="money-title">
                                        {{data.amountPurchase | money}}
                                    </h4>
                                    <div class="d-flex justify-space-between align-center">
                                        <p class="main-text mr-3">{{data.nextPay[0].date}}</p>
                                        <p class="main-text">{{data.siteName}}</p>
                                    </div>
                                </div>
                            </div>
                            <v-expansion-panel-header>
                            </v-expansion-panel-header>
                        </div>
                        <v-expansion-panel-content class="info-block-body pt-6">
                            <div class="pt-2">
                                <div
                                    class="d-flex justify-space-between mt-2"
                                    v-for="(schedule, i) in data.schedule"
                                    :key="i"
                                >
                                    <p :class="schedule.statusPayments == 3 && 'error-text'" class="main-text">
                                        {{ schedule.dtInsert }}
                                    </p>
                                    <p :class="schedule.statusPayments == 3 && 'error-text'" class="main-text d-flex align-center">
                                        <!--                                        <img v-if="schedule.statusPayments == 2 && $root.incomingData.theme == 'purple'" class="mr-1" src="@/assets/img/check-icon-purple.png" alt="">-->
                                        <img v-if="schedule.statusPayments == 2" class="mr-1" src="@/assets/img/check-icon.svg" alt="">

                                        {{ schedule.amountPayment | money }}
                                    </p>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </div>
            </div>
        </v-expansion-panels>
    </div>
</template>

<script>
import money from "@/helpers/moneyFormat";
import {changeDateFormat, dateWithoutYear} from "@/helpers/Date";
import {card} from "@/helpers";
export default {
    filters: {
        money,
        dateWithoutYear,
        card,
        changeDateFormat
    },

    methods: {
        getRepaidLoan(data) {
            data.repaidLoan = true;
            return data;
        }
    }
};
</script>

<style scoped></style>
