import moment from 'moment'

export function moneyFormat(value) {
	return value.toLocaleString();
}

export function card(value) {
    let cardType = 'Mir'
    if(value.split('')[0] === '4') {
        cardType = 'Visa';
    } else if(value.split('')[0] === '5') {
        cardType = 'MasterCard';
    }
    return cardType + value.replace(/(.{8})/, "");
}

export function yearsBeforeNow(years) {
    return moment(Date.now()).add(-years, 'years').add(-1, 'days');
}

export function makeMoment(date) {
    if (/\d{2}\.\d{2}\.\d{4}/.test(date)) {
        return moment(date, 'DD.MM.YYYY');
    }

    if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        return moment(date, 'YYYY-MM-DD');
    }

    return { isValid: () => false };
}
