<template>
    <div>
        <h4 class="block-title font-weight-bold text-center">
            Продлить график платежа
        </h4>

        <input-radio
            class="mt-6 center"
            value="6"
            @change="changeSelection"
            name="paymentShedule"
        >
            <p>
                6 месяцев
                {{
                    sixMonthPayment
                        ? `по ${sixMonthPayment} р.`
                        : ""
                }}
            </p>
        </input-radio>
        <input-radio
            class="mt-6 center"
            value="12"
            @change="changeSelection"
            name="paymentShedule"
        >
            <p>
                12 месяцев
                {{
                    twelveMonthPayment
                        ? `по ${twelveMonthPayment} р.`
                        : ""
                }}
            </p>
        </input-radio>

        <div class="mt-8">
            <p class="error-text text-center" v-show="errorMessage">
                {{ errorMessage }}
            </p>

            <v-btn
                :loading="loading"
                :disabled="!sheduleChanged"
                @click="confirmChange"
                :color="$root.incomingData.theme"
                class="main-btn mt-3"
            >
                {{
                    activeButton
                        ? `Оплатить ${monthlyPayment} руб.`
                        : "Подтвердить изменение"
                }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import InputRadio from "@/components/fields/inputRadio";

export default {
    components: {InputRadio},
    computed: {
        settings() {
            return this.$root.modals.settings;
        },
    },

    data: () => ({
        activeButton: false,
        sheduleChanged: false,
        loading: false,
        shuduleValue: 0,
        errorMessage: "",
        monthlyPayment: 0,
        sixMonthPayment: 0,
        twelveMonthPayment: 0,
    }),

    mounted() {
        this.$connector.getToken().then((data) => {
            if (data.apisetup.status === "done") {
                localStorage.setItem("token", data.apisetup.token);
                localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                this.$connector.changeShedule(6).then(data => this.sixMonthPayment = parseInt(data.apichangeschedule.monthlyPayment));
                this.$connector.changeShedule(12).then(data => this.twelveMonthPayment = parseInt(data.apichangeschedule.monthlyPayment));
            }
        });

    },

    methods: {
        changeSelection(val) {
            this.loading = true;
            this.shuduleValue = val
            this.$connector.getToken().then((data) => {
                if (data.apisetup.status === "done") {
                    localStorage.setItem("token", data.apisetup.token);
                    localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                    this.$connector
                        .changeShedule(val)
                        .then((answer) => {
                            const data = answer.apichangeschedule;

                            if (data.isSuccess) {
                                if (parseInt(data.amountPay) > 0) {
                                    this.monthlyPayment = parseInt(data.monthlyPayment);
                                    this.activeButton = true;
                                }

                                this.sheduleChanged = true;
                            } else {
                                this.errorMessage = data.isError;
                            }
                        })
                        .finally(() => (this.loading = false));
                }
            });
        },

        confirmChange() {
            this.loading = true;

            if (this.activeButton) {
                this.$connector.getToken().then((data) => {
                    if (data.apisetup.status === "done") {
                        localStorage.setItem("token", data.apisetup.token);
                        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                        this.$connector
                            .changeShedule(this.shuduleValue, false)
                            .then((answer) => {
                                const data = answer.apichangeschedule;

                                if (data.isSuccess && !data.isError) {
                                    this.$root.alertSettings = {
                                        text: 'График платежей успешно изменен, денежные средства списаны с привязанной карты. ',
                                        type: "success",
                                    };

                                    this.$root.modals.close();
                                } else {
                                    this.$root.alertSettings = {
                                        text: 'Ошибка при попытке изменить график. Обратитесь в техническую поддержку. ',
                                        type: "error",
                                    };
                                }
                            })
                            .catch((error) => {
                                this.$root.alertSettings = {
                                    text: error,
                                    type: "error",
                                };
                            })
                            .finally(() => (this.loading = false));
                    }
                })
            } else {
                this.$connector.getToken().then((data) => {
                    if (data.apisetup.status === "done") {
                        localStorage.setItem("token", data.apisetup.token);
                        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                        this.$connector
                            .changeShedule(this.shuduleValue, false)
                            .then((answer) => {
                                const data = answer.apichangeschedule;

                                if (data.isSuccess && data.result == 9) {
                                    this.$root.modals.close();

                                    this.$root.steps.setStep("SmsPage", {
                                        action: this.checkSms,
                                        page: 'SinglePurchase'
                                    });
                                } else {
                                    this.$root.alertSettings = {
                                        text: data.isError,
                                        type: "success",
                                    };
                                }
                            })
                            .catch((error) => {
                                this.$root.alertSettings = {
                                    text: error,
                                    type: "error",
                                };
                            })
                            .finally(() => (this.loading = false));
                    }
                })
            }
        },

        checkSms(isResend) {
            if (!isResend) {
                this.$connector.getToken().then((data) => {
                    if (data.apisetup.status === "done") {
                        localStorage.setItem("token", data.apisetup.token);
                        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                        this.$connector.changeShedule(
                            this.shuduleValue,
                            false,
                            this.$anketa.codeSms.value
                        ).then((answer) => {
                            const data = answer.apichangeschedule;

                            if(!data.isSuccess && data.result == 7) {
                                this.$root.alertSettings = {
                                    text: "Некорректный СМС код",
                                    type: "error",
                                };
                            }

                            if (data.isSuccess && data.result == 0) {
                                this.$root.alertSettings = {
                                    text: "График платежей успешно изменен",
                                    type: "success",
                                };

                                this.$root.steps.setStep("SinglePurchase");
                            }
                        });
                    }
                })
            } else {
                this.$connector.getToken().then((data) => {
                    if (data.apisetup.status === "done") {
                        localStorage.setItem("token", data.apisetup.token);
                        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                        this.$connector
                            .changePaymentDates(
                                this.$anketa.paymentDate.value,
                                0,
                                false
                            )
                            .then((data) => {
                                if (data.apichangepaymentdates.result == 0) {
                                    this.$root.steps.setStep('SinglePurchase');
                                    this.$root.alertSettings = {
                                        text: 'Дата платежа успешно изменена!',
                                        type: 'success'
                                    }
                                } else if (data.apichangepaymentdates.result == 9) {
                                    this.$root.steps.setStep("SmsPage", {
                                        action: this.checkSms,
                                        page: 'SinglePurchase'
                                    });
                                } else {
                                    this.errorMessage = data.apichangepaymentdates.isError;
                                }
                            });
                    }
                })
            }
        }
    },
};
</script>
