<template>
    <div class="choice">
        <div
            v-for="data in uniqueCards"
            :key="data.title"
            class="d-flex mt-2 align-center justify-space-between border-block"
        >
            <input-radio
                class="center"
                :value="data.pan"
                :id="data.payId"
                name="card"
                @change="changeCard"
                :checked="data.pan === activeCard"
            >
                <p class="big-text">
                    {{ data.pan | card }}
                </p>
            </input-radio>


            <img :src="require(`@/assets/img/${data.cardType}.png`)" alt=""/>
        </div>

        <p @click="addCard" class="blue-text d-inline-block mt-4">
            + Добавить карту
        </p>

        <p class="main-text mt-1 ml-3">Для проверки будут списаны 10 ₽. После тестового списания деньги будут возвращены на счет</p>

        <v-btn
            :loading="loading"
            @click="selectCard"
            :color="$root.incomingData.theme"
            class="fixed-btn main-btn"
        >
            Выбрать
        </v-btn>
    </div>
</template>

<script>
import InputRadio from "@/components/fields/inputRadio";
import {card} from "@/helpers";

export default {
    components: {InputRadio},

    props: {
        settings: Object
    },

    data: () => ({
        loading: false,
        activeCard: 0,
        cards: []
    }),

    filters: {
        card,
    },

    computed: {
        uniqueCards() {
            let uniqueCards = this.cards.filter((item, index, array) => {
                let cardType = 'Mir';
                let cardNumber = item.pan.split('');

                if (cardNumber[0] === '4') {
                    cardType = 'Visa';
                } else if (cardNumber[0] === '5') {
                    cardType = 'MasterCard';
                }

                item.cardType = cardType;


                let id = this.settings?.appId || this.$root.incomingData.title;

                console.log(id)

                if (item.appId === id && item.active) {
                    this.activeCard = item.pan;
                }

                return array.map((mapItem) => mapItem.pan).indexOf(item.pan) === index;
            })

            return uniqueCards;
        }
    },

    mounted() {
        this.$root.bnplOrderId = this.settings?.appId || this.$root.bnplOrderId;

        this.$connector.getToken().then((data) => {
            localStorage.setItem("token", data.apisetup.token);
            localStorage.setItem("bnplParentId", data.apisetup.parent_id);

            if (data.apisetup.status === "done") {
                this.$connector.getcards()
                    .then(data => {
                        this.$root.payId = data.cards[0].payId;
                        this.cards = data.cards;
                    })
            }
        });
    },

    methods: {
        selectCard() {
            this.$connector.getToken().then((data) => {
                localStorage.setItem("token", data.apisetup.token);
                localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                if (data.apisetup.status === "done") {
                    this.$connector.selectCard()
                        .then(data => {
                            if (data.cards) {
                                this.cards = data.cards;

                                this.$root.alertSettings = {
                                    text: 'Карта для списаний успешно изменена.',
                                    type: "success",
                                };
                            } else {
                                this.$root.alertSettings = {
                                    text: 'Ошибка при попытке изменить карту для списаний. Пожалуйста, попробуйте позже или обратитесь в техническую поддержку сервиса.',
                                    type: "error",
                                };
                            }

                        })
                        .catch(() => {
                            this.$root.alertSettings = {
                                text: 'Ошибка при попытке изменить карту для списаний. Пожалуйста, попробуйте позже или обратитесь в техническую поддержку сервиса.',
                                type: "error",
                            };
                        })
                }
            });

        },

        changeCard(val) {
            console.log(val)
            this.$root.payId = val;
        },

        addCard() {
            this.$connector.getToken().then((data) => {
                localStorage.setItem("token", data.apisetup.token);
                localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                if (data.apisetup.status === "done") {
                    this.$connector.addCard(this.settings?.appId || this.$root.incomingData.title)
                        .then(data => {
                            if (!data.redirect) {
                                this.$root.alertSettings = {
                                    text: 'Произошла ошибка при попытке привязать новую карту. Пожалуйста, попробуйте позже или обратитесь в техническую поддержку сервиса.',
                                    type: "error",
                                };
                            } else {
                                window.open(data.redirect, '_self').focus();
                            }
                        })
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.choice {
    margin-top: 42px;

    .big-text {
        color: #0a0a0d;
        font-weight: 600;
    }

    img {
        height: 20px;
    }
}
</style>
