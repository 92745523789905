export default {
	data() {
		return {
			steps: {
				current: 1,
				// getCurrent: (step = 1) => new Promise(
				// 	resolve => {
				// 		// this.connector.getCurrentStep().then(
				// 		// 	data => {
				// 		this.steps.setStep(step);
				// 					resolve(true);
				// 			// }
				// 		// );
				// 	},
				// ),
				isSamePage: false,
				setStep: (step, data, prevPage) => {
					this.steps.isSamePage = (this.steps.current == step) && (step != 1);
					this.steps.current = step;

					switch (step) {
                        case 'Auth':
                            this.forms.open('Auth');
                            break;
						//для новой сессии(шаг 0) или шага 1
						case 'Main':
							this.forms.open('Main');
							break;
                        // 3 Заполнение анкеты (шаг 1)
                        case 'SeveralPurchases':
                            this.forms.open('SeveralPurchases');
                            break;
                        // 3 Заполнение анкеты (шаг 1)
                        case 'RepaidLoans':
                            this.forms.open('RepaidLoans', 'История заказов', 'SeveralPurchases');
                            break;
						// 2 Выбор тарифа
						case 'SinglePurchase':
							this.forms.open('SinglePurchase', 'Оплата частями', 'SeveralPurchases', data);
							break;
                        // 3 Заполнение анкеты (шаг 1)
                        case 'OrderInfo':
                            this.forms.open('OrderInfo', 'Информация по заказу', 'SinglePurchase', data );
                            break;
                        // 3 Заполнение анкеты (шаг 1)
                        case 'PaymentShedule':
                            this.forms.open('PaymentShedule', 'График платежей', 'OrderInfo');
                            break;
                        // Страница с выбором платежа
                        case 'ChangePaymentDate':
                            this.forms.open('ChangePaymentDate', 'Изменение даты платежа', 'SinglePurchase');
                            break;
                        // Страница с выбором платежа
                        case 'PaymentMethods':
                            this.forms.open('PaymentMethods', 'Способы оплаты', 'SinglePurchase');
                            break;
                        // Страница с выбором карты для платежа
                        case 'ChoiceCard':
                            this.forms.open('ChoiceCard', 'Выберите карту', prevPage, data);
                            break;
                        // Страница с проверкой смс кода
                        case 'SmsPage':
                            this.forms.open('SmsPage','', '', data);
                            break;
                        // Страница с документами
                        case 'Documents':
                            this.forms.open('Documents', 'Документы', 'OrderInfo');
                            break;
                        // Прелоадер
                        case 'Preloader':
                            this.forms.open('Preloader');
                            break;
						default:
					}

					// widget.scrollFrameTo('start');
					this.steps.isSamePage = false;
				},
			}
		}
	}
}
