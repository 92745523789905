import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import anketa from "./mixins/anketa";
import connector from "./mixins/connector";
import forms from "./mixins/forms";
import steps from "./mixins/steps";
import "./assets/scss/style.scss";

Vue.config.productionTip = false;
import VueMask from "v-mask";
import modals from "@/mixins/modals";

Vue.use(VueMask);

Vue.mixin({
    computed: {
        // $CLObject() {
        //     return this.$root.CLObject;
        // },
        $anketa() {
            return this.$root.anketa;
        },
        $forms() {
            return this.$root.forms;
        },
        $modals() {
            return this.$root.modals;
        },
        $connector() {
            return this.$root.connector;
        },
        $isMobile() {
            return this.$root.windowWidth <= 428;
        },
    },
});

new Vue({
    vuetify,
    mixins: [anketa, connector, forms, steps, modals],
    render: (h) => h(App),

    data: () => ({
        hasAuth: false,

        bnplOrderId: 0,
        smsError: '',
        theme: 'pink',

        alertSettings: {
        },

        incomingData: {
            amountPurchase: 0,
            remainsPay: 0,
            dateChangeCost: 0,
            allAmountPurchase: 0,
            allRemainsPay: 0,
            redemptionCondition: false,

            cards: [],

            nextPay:{
                date: '',
                amount: 0
            },
            nearNextPay:{
                date: '',
                amount: 0
            },
            //добавлен для сохранения состояния earlyRedemption в SinglePurchase при переходе на другую форму
            singlePurchase: {
                earlyRedemption: 0,
            },
            orderInfo: {
                currentSubs: {}
            },
            subs: []

        //     // remainsPay: 0,
        //     // amountPurchase: 0,
        //     // balance: 0,
        //     // card_number: 1111222233334444,
        //     // date: 14,
        //     // nextPay: 5000,
        //     // monthlyPay: 2500,
        //     // nextPayDate: "13 октября",
        //     // debt: 0,
        //     // amountPay: 0,
        //     //
        //     // monthlyPayment: '',
        //     //
        //     // // orderInfo
        //     // accruals: 0,
        //     // title: 2102973052,
        //     // dtClientAgreed: '01.01.2021',
        //     // overdueDebt: 2500,
        //     // totalDebt: 300000,
        //     // nextPayment: 3000,
        //     // dateNextPayment: '01.01.2021',
        //     //
        //     // payments: [
        //     //     {
        //     //         date: "21.12.2021",
        //     //         nextPayDate: "14 апреля",
        //     //         nextPay: 3000,
        //     //
        //     //         schedule: [
        //     //             {
        //     //                 statusPayments: 2,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //             {
        //     //                 statusPayments: 2,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //             {
        //     //                 statusPayments: 3,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //             {
        //     //                 statusPayments: 1,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //         ],
        //     //     },
        //     //     {
        //     //         date: "29.12.2021",
        //     //         nextPayDate: "18 апреля",
        //     //         nextPay: 3600,
        //     //
        //     //         schedule: [
        //     //             {
        //     //                 statusPayments: 2,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //             {
        //     //                 statusPayments: 2,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //             {
        //     //                 statusPayments: 3,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //             {
        //     //                 statusPayments: 1,
        //     //                 nextPayDate: "14.02.2022",
        //     //                 nextPay: 2500,
        //     //             },
        //     //         ],
        //     //     },
        //     ],
        },
    }),
    computed: {
        isMobile() {
            return this.windowWidth <= 428;
        },
        // windowWidth() {
        //   return window.innerWidth;
        // },
        // windowHeight() {
        //   return window.innerHeight;
        // },
    },
}).$mount("#app");
