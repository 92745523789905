import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true,
    },

    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // siab
                yellow: "#FBECAE",
                purple: "#9c6cfe",
                grey: "#848485",
                green: "#26A212",
                red: "#FA656E",
                black: "#161619",
            },
        },
    },
});
