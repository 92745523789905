<template>
  <v-fade-transition>
    <div v-if="!closed" class="block-text br-12">
      <slot></slot>
      <img @click="closed = true" class="close" src="@/assets/img/close.svg" alt="" />
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  data: () => ({
    closed: false
  })
}
</script>

<style scoped>

</style>
