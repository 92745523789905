<template>
    <div class="main-page">
        <div
            v-for="data in info"
            :key="data.title"
        >
            <div class="d-flex justify-space-between mt-6" v-if="data.visible">
                <p class="big-text">
                    {{ $root.incomingData.theme === 'purple' && data.renaissanceTitle ? data.renaissanceTitle : data.title }}

                    <span
                        @click="$modals.open('tooltipModal', data.tooltip)"
                        v-if="data.tooltip && $root.incomingData.theme !== 'purple'"
                        class="tooltip-icon pointer ml-2"
                    >
                        <img src="@/assets/img/tooltip-icon.svg" alt="" />
                    </span>

                </p>
                <p class="big-text black--text">{{ data.value }}</p>
            </div>
        </div>

        <div class="pt-4">
            <div
                @click="$root.steps.setStep('PaymentShedule')"
                class="block-link pointer d-flex align-center mt-6"
            >
                <div class="circle__img mr-4">
                    <img class="scaled-img" v-if="$root.incomingData.theme === 'purple'"  src="@/assets/img/calendar-purple.svg" alt="" />
                    <img v-else src="@/assets/img/shedule-calendar.svg" alt="" />
                </div>

                <h4 class="main-title">График платежей</h4>

                <img
                    class="arrow-right"
                    src="@/assets/img/arrow-right.svg"
                    alt=""
                />
            </div>
            <div
                @click="$root.steps.setStep('Documents')"
                class="block-link pointer d-flex align-center mt-6"
            >
                <div class="circle__img mr-4">
                    <img class="scaled-img" v-if="$root.incomingData.theme === 'purple'"  src="@/assets/img/docs-purple.svg" alt="" />
                    <img v-else src="@/assets/img/docs.svg" alt="" />
                </div>

                <h4 class="main-title">Документы</h4>

                <img
                    class="arrow-right"
                    src="@/assets/img/arrow-right.svg"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import money from "@/helpers/moneyFormat.js";

export default {
    computed: {
        info() {
            const incomingData = this.$root.incomingData;
            const service = incomingData?.optionalServices;
            let additionalServices = 0;

            if(service?.sms > 0 && (service?.changeSchedule + service?.transferDateOfPay) > 0) {
                additionalServices = service.sms + service.changeSchedule + service.transferDateOfPay;
            } else if(service?.sms > 0 && (service?.changeSchedule + service?.transferDateOfPay) === 0) {
                additionalServices = service.sms;
            }

            return [
                {
                    title: "Следующее списание",
                    value: incomingData.nextPay.date,
                    visible:true,

                },
                {
                    title: "Сумма след. платежа",
                    value: money(incomingData.nextPay.amount),
                    visible:true,

                },
                {
                    title: "Общая задолженность",
                    renaissanceTitle: 'Просроченные платежи',
                    value: money(incomingData.remainsPay),
                    visible: true,

                    tooltip: {
                        title: "Общая задолженность",
                        body: [
                            {
                                text: "Остаток основного долга",
                                value: money(incomingData.principal),
                            },
                            {
                                text: "Плата за сервис",
                                value: money(incomingData?.percentage),
                            },
                            {
                                text: "Штрафы",
                                value: money(incomingData.fine + incomingData.feeOverdue),
                            },
                            {
                                text: "Дополнительный сервис",
                                value: money(incomingData.orderInfo.currentSubs.subsDay !== undefined ? incomingData.orderInfo.currentSubs.subsDay : 0),
                            },
                        ],
                        button: 'Понятно'
                    },
                },
                {
                    title: "Дата заказа",
                    value: this.$root.incomingData.agreementDate,
                    visible:true
                },
                { title: "Лицевой счет", value: this.$root.incomingData.title, visible:true },
                {
                    title: "Просроченная задолженность",
                    value: money(this.$root.incomingData.overdueDebt),
                    visible: true,

                    tooltip: {
                        title: "Просроченная задолженность",
                        body: [
                            {
                                text: "Остаток основного долга",
                                value: money(this.$root.incomingData.principalDebt),
                            },
                            {
                                text: "Плата за сервис",
                                value: money(this.$root.incomingData.percentageDebt),
                            },
                            {
                                text: "Штрафы",
                                value: money(incomingData.fine + incomingData.feeOverdue),
                            },
                            {
                                text: "Дополнительный сервис",
                                value: money(incomingData.orderInfo.currentSubs.subsDelayDay !== undefined ? incomingData.orderInfo.currentSubs.subsDelayDay : 0),
                            },
                        ],
                        button: 'Понятно'
                    },
                },
                {
                    title: "Аванс",
                    visible: this.$root.incomingData.theme !== 'purple',
                    value: money(this.$root.incomingData.accruals),
                    tooltip: {
                        title: "Аванс",
                        body: [
                            {
                                value: 'Денежные средства, поступившие на лицевой счет, но еще не списанные в счет ежемесячногоплатежа',
                            },
                        ],
                        button: 'Понятно'
                    },
                },
                {
                    title: "Дополнительный сервис",
                    value: money(additionalServices),
                    visible: true,
                },
            ];
        },
        incomingData(){
            return this.$root.incomingData;
        }
    },
    mounted(){
        if(this.$root.forms.settings) {
            this.$root.incomingData.orderInfo.currentSubs = this.$root.forms.settings.subs[0];
        }
    },

    filters: {
        money,
    },
};
</script>
