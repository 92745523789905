export default {
	data() {
		return {
			forms: {
				active: 'Preloader',
                title: '',
                prevPage: '',
				settings: {},
				// у функций не стоит использовать больше трех параметров
				open( value, title, prevPage, settings) {
					this.active = value;
                    this.prevPage = prevPage;
                    this.settings = settings;
					this.title = title;
				},
				close() {
					this.active = 'нет';
					this.settings = {};
				},
			},
		}
	}
}
