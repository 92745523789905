export default {
	data() {
		return {
			modals: {
				active: 'нет',
				settings: {},
				open( name, settings ) {
					this.active = name;
					this.settings = settings;
				},
				close() {
					this.active = 'нет';
					this.settings = {};
				},
				hasActive() {
					return this.active !== 'нет';
				},
			}
		}
	}
}