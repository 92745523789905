import moment from "moment";

export function changeDateFormat(str) {
    return moment(str).format('L').replace(/\./g, '-');
}

export function dateWithYear(str) {
    return moment(str).format('ll');
}

export function dateWithoutYear(str) {
    return moment(str).format('LL').replace(/[0-9]{4} г./,'')
}
